


















import Vue from 'vue';


export default Vue.extend({
  name: 'FAQ',
  data: () => ({
    faq: global.piafAgentConfig.FAQ,
  }),
  props: [
  ],
  methods: {
  },
});
