





























import Vue from 'vue';

export default Vue.extend({
  name: 'Head',
  data: () => ({
    clientName: global.piafAgentConfig.DISPLAY_NAME,
    clientDesc: global.piafAgentConfig.DISPLAY_DESCRIPTION
  }),
});
