










































import Vue from 'vue';
import { mapState } from 'vuex'
import Spinner from './Spinner.vue'
import Filters from './Filters.vue'
import Answer from './Answer.vue'
import StarRating from 'vue-star-rating'
import {sendFeedbackAsync, Answer as AnswerFromBackend} from '@/store/api_utils'
import {Feedback} from '@/feedback'

export default Vue.extend({
  name: 'Answers',
  data: () => ({
    ready: false,
    useFilters: global.piafAgentConfig.FILTERS.data.length > 0,
    allowFeedback: global.piafAgentConfig.ALLOW_FEEDBACK,
    rating: 0,
    ratingSent: false
  }),
  computed: {
    ...mapState([
     'answers',
     'question'
   ]),
 },
  components: {
    Spinner,
    Answer,
    Filters,
    StarRating
  },
  methods: {
    hasAnswers(): boolean {
      return this.answers.length > 0 && this.answers.some((ans: AnswerFromBackend) => (Number(ans.probability) > 0.2 || (ans?.meta?.weight ?? 0) > 50))
    },
    // here we have to define unsubscribe (otherwise, Typescirpt says this has no funciton such as unsubscribe)
    unsubscribe(): void{
      // console.log('here');
    },
    unsubscribe2(): void{
      // console.log('here');
    },
    sendFeedbacks(): void{
      const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
      console.log('\nrating:',this.rating, ' | isbot:',isBot);
      // we take this hypothesis: if rating < 3 then all answers are considered wrong
      // if satisfied, we can't tell which answer is correct from here.
      // so we don't do anything : we hope the person clicks on the link
      // since clicking the link will send a positive feedback on this answer
      if(this.rating < 3){
        for (const obj of this.answers) {
          const feedback: Feedback = {
            question: this.question,
            is_correct_answer: false,
            document_id: obj.document_id,
            model_id: obj.model_id,
            is_correct_document: false,
            answer: obj.answer,
            offset_start_in_doc: obj.offset_start
          }
          sendFeedbackAsync(feedback)
        }
      }
      if (global.piafAgentConfig.MATOMO) {
        // we save the rating
        window._paq.push(['trackEvent', 'search', window.location.pathname, 'rating' , this.rating])
      }
      this.ratingSent = true
    }
  },
  beforeDestroy(): void {
    this.unsubscribe();
    this.unsubscribe2();
  },
  created: function() {
    this.$store.dispatch('callInference');

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setAnswers') {
        if (state.answers) {
          this.ready = true
          // then we save the search results
          window._paq.push(['trackSiteSearch',
            // Search keyword searched for
            this.question,
            // Search category selected in your search engine.
            window.location.pathname,
            this.answers.length
          ]);
        }
      }
    });

    this.unsubscribe2 = this.$store.subscribeAction((action) => {
      if (action.type === 'callInference') {
        this.ready = false
      }
    })
  },

});
