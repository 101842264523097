




















import Vue from 'vue';

import SelectText from '@vinyll/selecttext'
import {sendFeedbackAsync} from '@/store/api_utils'
import {Feedback} from '@/feedback'

export default Vue.extend({
  name: 'Answer',
  data: () => ({
    displayProbability: global.piafAgentConfig.DISPLAY_PROBABILITIES,
    allowFeedback: global.piafAgentConfig.ALLOW_FEEDBACK,
    tagname: global.piafAgentConfig.TAGNAME
  }),
  props: [
    'answer',
    'question',
  ],
  methods: {
    answerified() {
      return this.answer.meta.answerified ?? false
    },
    displayTitle(): boolean {
      return global.piafAgentConfig.DISPLAY_TITLES && this.answer.meta.name
    },
    displayAnswer(): boolean {
      return Number(this.answer.probability) > 0.2 || (this.answer?.meta?.weight ?? 0) > 50
    },
    getContext(): string {
      if (this.answerified()) {
        return this.answer.context.substring(0, 200)
      } else {
        return  this.answer.context
      }
    },
    printAnswer(): void{
      const paragraph: any = this.$refs.answ
      if (!paragraph) {
        return
      }
      if (!this.answerified()) {
        const selector = new SelectText(paragraph)
        selector.addSelection(paragraph.textContent.indexOf(this.answer.answer), this.answer.answer.length)

        // here we have to clone this to remove the addeventlistner that selects text
        const elClone = selector.container.cloneNode(true);
        paragraph.parentNode.replaceChild(elClone, paragraph);
      }
    },
    // here we have to define unsubscribe (otherwise, Typescirpt says this has no funciton such as unsubscribe)
    unsubscribe(): void{
      // console.log('here');
    },
    bgColorTrust(): string {
      let bgColor: string
      if (this.answer.probability < 0.5) {
        bgColor = 'bg-red-700'
      } else if (this.answer.probability < 0.6) {
        bgColor = 'bg-orange-700'
      } else if (this.answer.probability < 0.7) {
        bgColor = 'bg-yellow-600'
      } else if (this.answer.probability < 0.8) {
        bgColor = 'bg-teal-700'
      } else if (this.answer.probability < 0.9) {
        bgColor = 'bg-green-600'
      } else {
        bgColor = 'bg-green-800'
      }
      return bgColor
    },
    sendFeedback(): void{
      if(!this.allowFeedback){
        return
      }
      const feedback: Feedback = {
        question: this.question,
        is_correct_answer: true,
        document_id: this.answer.document_id,
        model_id: this.answer.model_id,
        is_correct_document: true,
        answer: this.answer.answer,
        offset_start_in_doc: this.answer.offset_start
      }
      sendFeedbackAsync(feedback)
      return
    }
  },
  beforeDestroy(): void {
    this.unsubscribe();
  },
  mounted: function() {
    this.$nextTick(function () {
      // execute this only after the HTML get fully rendered
      this.printAnswer()
    })
  },
});
