

























import Vue from 'vue';
import FAQ from "@/components/FAQ.vue";
// Waiting for types: https://github.com/trevoreyre/autocomplete/issues/58
// eslint-disable-next-line
// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue'
import levenshtein from 'js-levenshtein'


export default Vue.extend({
  name: 'Question',
  components: { FAQ, Autocomplete },
  data: () => ({
    newQuestion: '',
    exampleQuestion: global.piafAgentConfig.EXAMPLE_QUESTION,
    autocompleteQuestions: global.piafAgentConfig.AUTOCOMPLETE_QUESTIONS
  }),
  computed: {
    error: function(): string {
      if (this.newQuestion === '') {
        return 'Votre question ne peut être vide'
      } else if (this.newQuestion.trim().slice(-1) !== '?') {
        return 'Votre question doit finir par un \'?\''
      }
      return ''
    }
  },
  methods: {
    retrieveSuggestions(input: string) {
      if (input.length < 1) { return [] }
      this.newQuestion = input;
      return this.autocompleteQuestions.questions
          .filter(question => {
            return question.toLowerCase().includes(input.toLowerCase())
          })
          .sort((first_string, second_string) => {
            return levenshtein(input, first_string) - levenshtein(input, second_string);
          })
          .slice(0, 10)
    },
    handleAutoCompleteSubmit(value: string | undefined) {
      if (value === undefined) {
        // Retrieving the value directly in the autocomplete component when no suggestion match
        // eslint-disable-next-line
        // @ts-ignore
        value = this.$refs.autocomplete?.value;
      }
      this.newQuestion = value ?? '';
      this.onClick();
    },
    onClick(){
      if (this.error === '') {
        this.$store.commit('setQuestion',this.newQuestion)
        this.$router.push('Answers')
      } else {
        return
      }
    },
  },


});
